import React from 'react'
import Header from '../Coman/Header'
import Sidebar from '../Coman/Sidebar'
import Maind from './Maind'
import DashboardTable from './DashboardTable'

export default function Dashboard() {
  return (
    <div>
      <Header />
      <Sidebar />
      <div className='admin-wrapper'>
        <Maind />
        <DashboardTable />
      </div>

    </div>
  )
}
