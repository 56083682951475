import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProjectContext } from "../../ProjectContext";

export default function Sidebar() {
  const { account, disconnectNow, dbuser, copyaddress, formatAddress, connectMetamask } = useContext(ProjectContext);

  const [button, setButton] = useState(false);
  var navigate = useNavigate();

  const logout = () => {
    disconnectNow();
    navigate('/')
  }

  const handleChange = () => {
    setButton(!button);
    handleToggle(!button); // Call handleToggle with the new state
  };

  const handleToggle = (isActive) => {
    const body = document.body;
    if (isActive) {
      body.classList.add("dashboard-header-active");
      localStorage.setItem("darkMode", "true");
    } else {
      body.classList.remove("dashboard-header-active"); // Correct class name
      localStorage.setItem("darkMode", "false");
    }
  };
  const currentPath = window.location.pathname;

  return (
    <div>
      <header className="header-area dashboard-header px-0 px-sm-0">
        <nav className="navbar navbar-expand-lg bg-white">
          <div className="container-fluid">
            <div className="d-flex align-items-center">
              <div
                className="menu-toggler ms-2 ms-sm-3"
                id="dashboardMenuTrigger"
                onClick={handleChange}
              >
                <i className="bi bi-list" />
              </div>
              {/* Brand Logo */}
              {/* <div className="admin-logo me-1 me-sm-3">
                <img src="assets/img/core-img/dashboard-logo.png" alt="" />
              </div> */}

              {/* Search Form */}
              {/* <div className="search-form position-relative d-flex align-items-center">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search"
                />
                <button className="position-absolute" type="submit">
                  <i className="bi bi-search" />
                </button>
              </div> */}
            </div>
            {/* Header Meta */}
            <div className="header-meta d-flex align-items-center">
              {/* Notification */}
              {/* <div className="user-dropdown dropdown mx-2 mx-sm-3">
                <button
                  className="btn dropdown-toggle user-btn"
                  id="dd10"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="assets/img/core-img/notification.png" alt="" />
                </button>
           
              </div> */}
              {/* User Dropdown */}
              {/* <div className="user-dropdown dropdown">
                <button
                  className="btn dropdown-toggle user-btn"
                  id="dd20"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="assets/img/core-img/user.png" alt="" />
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end mt-3"
                  aria-labelledby="dd20"
                >
                  <li>
                    <a className="dropdown-item" href="dashboard.html">
                      <i className="me-2 bi bi-person-circle" />
                      Dashboard
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="live-bids.html">
                      <i className="me-2 bi bi-hammer" />
                      My bids
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="my-collection.html">
                      <i className="me-2 bi bi-collection" />
                      Collection
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="settings.html">
                      <i className="me-2 bi bi-gear" />
                      Settings
                    </a>
                  </li>
                </ul>
              </div> */}
              {/* Menu Toggler */}
              {/* <div
                className="menu-toggler ms-2 ms-sm-3"
                id="dashboardMenuTrigger"
              >
                <i className="bi bi-list" />
              </div> */}
              {/* Button */}
              {!account ?
                <a
                  className="btn btn-sm new-button text-black rounded-pill ms-2 ms-sm-3 "
                  href="#"
                  onClick={() => connectMetamask()}
                >
                  <i class="bi bi-wallet2 me-2"></i>

                  Connect Wallet
                </a> :
                <a
                  className="btn btn-sm new-button text-black rounded-pill ms-2 ms-sm-3 "
                  href="#"
                >
                  <i class="bi bi-wallet2 me-2"></i>

                  {formatAddress(account)}
                </a>}
              {account ?
                <a
                  className="btn btn-sm new-button text-black rounded-pill ms-2 ms-sm-3 "
                  href="#"
                  onClick={() => logout()}
                >
                  <i class="bi-box-arrow-in-right me-2"></i>

                  Logout
                </a> :
                ''
              }
            </div>
          </div>
        </nav>
      </header>
      <div className="admin-sidebar-wrap">
        <div className="overflowY-scroll">
          {/* User Profile */}
          <div className="user-profile d-flex align-items-start ">
            {/* User Name */}
            <Link to="/">
              <div className=" mb-5">
                <img
                  src="assets/img/new-img/logo_dark.svg"
                  className="img-dark-done"
                  style={{ width: "150px" }}
                  alt=""
                />
                <img
                  src="assets/img/new-img/logo.svg"
                  className="img-light-done"
                  style={{ width: "150px" }}
                  alt=""
                />
              </div>
            </Link>
            <i
              class="bi bi-x ms-5 new-button rounded-pill p-1 px-2 mt-2 text-black icon-close"
              onClick={handleChange}
            ></i>
            {/* Balance */}
            {/* <div className="card shadow mb-5">
              <div className="card-body text-center p-4">
                <h6 className="mb-1">Current balance</h6>
                <h5 className="mb-0 text-dark d-flex align-items-center justify-content-center">
                  <img
                    className="me-1"
                    src="assets/img/core-img/ethereum.png"
                    alt=""
                  />
                  <span className="counter">4.09685</span>
                  <span className="ms-2">ETH</span>
                </h5>
                <a
                  className="btn btn-warning rounded-pill btn-sm w-100 mt-3"
                  href="#"
                >
                  Recharge
                </a>
              </div>
            </div> */}
          </div>
          {/* Sidenav */}
          <div className="sidenav">
            <ul>
              {/* <li>Menu</li> */}
              <li>
                <Link
                  className={`${currentPath === "/" ? "active " : " "}`}
                  to="/"
                >
                  <i className="bi bi-speedometer" />
                  DApp
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/market"
                  className={`${currentPath === "/market" ? "active " : " "}`}
                >
                  <i class="bi bi-shop"></i>
                  Market
                </Link>
              </li> */}
              <li>
                <Link
                  to="/subscriptions"
                  className={`${currentPath === "/subscriptions" ? "active " : " "
                    }`}
                >
                  <i className="bi bi-columns-gap" />
                  Subscriptions
                </Link>
              </li>
              <li>
                <Link
                  to="/profile"
                  className={`${currentPath === "/profile" ? "active " : " "}`}
                >
                  <i class="bi bi-person-square"></i>
                  Profile
                </Link>
              </li>
              <li>
                <Link
                  to="/tree"
                  className={`${currentPath === "/tree" ? "active " : " "}`}
                >
                  <i class="bi bi-tree"></i>
                  Tree
                </Link>
              </li>
              <li>
                <Link
                  to="/purchase-history"
                  className={`${currentPath === "/purchase-history" ? "active " : " "
                    }`}
                >
                  <i class="bi bi-bag"></i>
                  Purchase History
                </Link>
              </li>

              <li>
                <Link
                  to="/sponsor-incom"
                  className={`${currentPath === "/sponsor-incom" ? "active " : " "
                    }`}
                >
                  <i className="bi bi-columns-gap" />
                  Sponsor Income
                </Link>
              </li>
              <li>
                <Link
                  to="/matching-incom"
                  className={`${currentPath === "/matching-incom" ? "active " : " "
                    }`}
                >
                  <i className="bi bi-columns-gap" />
                  Matching Income
                </Link>
              </li>
              <li>
                <Link
                  to="/withdrawal"
                  className={`${currentPath === "/withdrawal" ? "active " : " "
                    }`}
                >
                  <i className="bi bi-wallet-fill" />
                  Withdrawal
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/profile"
                  className={`${currentPath === "/profile" ? "active " : " "}`}
                >
                  <i className="bi bi-wallet2" />
                  Profile
                </Link>
              </li> */}

              <li>
                <Link
                  to="/performance-income"
                  className={`${currentPath === "/performance-income" ? "active " : " "
                    }`}
                >
                  <i className="bi bi-gear" />
                  Performance Income
                </Link>
              </li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="mt-5" />
            {/* Mode Switching */}
            {/* <div className="dark-light-mode">
              <input id="darkLightSwitch" type="checkbox" />
              <label className="shadow" htmlFor="darkLightSwitch">
                Toggle
              </label>
            </div> */}
            {/* <div className="copywrite-text mt-4">
              <p className="mb-0 fz-14">
                2022 © All rights reserved by{" "}
                <a className="fz-14" href="#" target="_blank">
                  Designing World
                </a>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
