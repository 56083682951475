import React, { useContext, useEffect, useState } from "react";
import Header from "../../Coman/Header";
import Sidebar from "../../Coman/Sidebar";
import Tree from 'react-d3-tree';
import axios from 'axios';
import { ProjectContext } from "../../../ProjectContext";

export default function TreeView() {
  const { account, copyaddress, formatAddress } = useContext(ProjectContext)

  const [data, setdata] = useState({ name: "you" });
  const [isLoading, setisLoading] = useState(false);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  const CustomLabel = ({ nodeData }) => (
    <g>
      <text x="0" y="0" textAnchor="middle" fill="#fff">
        {nodeData.name}
      </text>
    </g>
  );
  const treeConfig = {
    nodeSize: { x: 800, y: 500 },
    separation: { siblings: 0.6, nonSiblings: 0.8 },
    initialDepth: 1,
    nodeSvgShape: {
      shape: "rect",
      shapeProps: {
        width: 0,
        height: 0,
        x: -20,
        y: 20,
        stroke: "#fff"
      }
    },
    allowForeignObjects: true,
    nodeLabelComponent: {
      render: <CustomLabel />,
      foreignObjectWrapper: {
        y: 0,
        x: 0,
      },
    },
    pathFunc: "step",
  };
  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <g >
      <foreignObject x="-150" y="-70" width="300" height="480" style={{ cursor: 'pointer' }}>
        <div className="col-sm-12" >
          <div className="card border-0 shadow-sm" >
            <div className="card-body p-4 text-center" >
              <div className="text-center" >
                <span><i className="bi bi-person-circle fs-2" /></span>
              </div>
              <div className="why-us__content" >
                <h4 type="button" > <a className="pricing__btn-white fs-4" type='button' onClick={() => copyaddress(nodeDatum.name)}>{formatAddress(nodeDatum.name)} <i className='bi bi-copy'></i></a></h4>
              </div>
              {nodeDatum.children?.length ?
                <a className="mt-4 " type='button' onClick={toggleNode}><span><i className="bi bi-arrow-down-circle fs-2" /></span></a>
                :
                ''}
            </div>
          </div>
        </div>

      </foreignObject>
    </g>
  );
  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "tree",
        // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
        address: account,
        // address: "0xe7b485d74aafb65e8b8135dbcb226bdcdf414877",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        console.log("translate={translate}", res.data);

        if (res.data.error) {
          return "";
        }
        setdata(res.data);
      });
  };

  useEffect(() => {
    getData();
  }, [account]);

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="admin-wrapper">
        <div className="container-fluid">
          <div className="row g-4 text-start">
            <div className="col-12" style={{ height: "1000px" }}>
              {isLoading ?
                <div className="text-center pt-4 fs-3">Loading...</div>
                :
                <Tree
                  data={data}
                  orientation="vertical"
                  {...treeConfig}
                  translate={translate}
                  scaleExtent={{ min: 0.1, max: 1 }}
                  isInitialRenderForDataset={true}
                  enableLegacyTransitions={true}
                  renderCustomNodeElement={renderRectSvgNode}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
