import React from "react";
import Header from "../../Coman/Header";
import Sidebar from "../../Coman/Sidebar";
import ProfileMain from "./ProfileMain";

export default function Profile() {
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="admin-wrapper">
        <ProfileMain />
      </div>
    </div>
  );
}
