import React from "react";
import Header from "../../Coman/Header";
import Sidebar from "../../Coman/Sidebar";
import SubscriptionsMain from "./SubscriptionsMain";

export default function Subscriptions() {
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="admin-wrapper">
        
        <SubscriptionsMain />
      </div>
    </div>
  );
}
