import React from "react";
import Header from "../../Coman/Header";
import Sidebar from "../../Coman/Sidebar";
import SponsorTable from "./SponsorTable";

export default function Sponsor() {
  return (
    <>
      <Header />
      <Sidebar />
      <div className="admin-wrapper">
        <SponsorTable />
      </div>
    </>
  );
}
