/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Pagination = ({
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  pages,
}) => {
  return (
    <>
      <div className="funto-pagination mt-4">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center mb-0">
            <li className={` page-item ${currentPage === 1 ? "active" : ""}`}>
              <a
                type="button"
                className="page-link previous"
                aria-label="Previous"
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1 ? true : false}
              >
                &lt;&lt;
              </a>
            </li>
            {currentPage - 2 > 0 ? (
              <li className=" page-item">
                <a
                  type="button"
                  className="page-link"
                  onClick={() => setCurrentPage((prev) => prev - 2)}
                ><span className="btn-border" />
                  {currentPage - 2}
                </a>
              </li>
            ) : (
              ""
            )}
            {currentPage - 1 > 0 ? (
              <li className=" page-item">
                <a
                  type="button"
                  className="page-link"
                  onClick={() => setCurrentPage((prev) => prev - 1)}
                ><span className="btn-border" />
                  {currentPage - 1}
                </a>
              </li>
            ) : (
              ""
            )}
            <li className=" page-item active">
              <a type="button" disabled={true} className="page-link">
                
                {currentPage}
              </a>
            </li>
            {currentPage + 1 <= pages ? (
              <li className=" page-item">
                <a
                  type="button"
                  className="page-link"
                  onClick={() => setCurrentPage((prev) => prev + 1)}
                >
                  <span className="btn-border" />
                  {currentPage + 1}
                </a>
              </li>
            ) : (
              ""
            )}
            {currentPage + 2 <= pages ? (
              <li className=" page-item">
                <a
                  type="button"
                  className="page-link next"
                  onClick={() => setCurrentPage((prev) => prev + 2)}
                >
                  <span className="btn-border" />
                  {currentPage + 2}
                </a>
              </li>
            ) : (
              ""
            )}
            <li
              className={` page-item next ${currentPage === pages ? "active" : ""}`}
            >
              <a
                type="button"
                className="page-link next"
                onClick={() => setCurrentPage(pages)}
                disabled={currentPage === pages ? true : false}
              >
                &gt;&gt;
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Pagination;
