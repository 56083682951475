import React from 'react'
import Header from '../../Coman/Header'
import Sidebar from '../../Coman/Sidebar'
import MarketMain from '../MarketMain'

export default function Market() {
  return (
    <div>
      <Header />
      <Sidebar />
      <div className='admin-wrapper'>
        <MarketMain />
      </div>
    </div>
  )
}
