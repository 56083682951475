import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../../ProjectContext";
import { useLocation } from "react-router-dom";
import axios from "axios";

export default function SubscriptionsMain() {
  const {
    bots,
    account,
    dbuser,
    connectMetamask,
    getudata,
    settings,
    esigner,
    ethers,
    stakcontract,
    getsmcs,
    copyaddress,
    getuserDB,
    formatAddress,
    balance,
    tokenrate,
    toastSuccess,
    toastError,
    smuser,
  } = useContext(ProjectContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const link_ref_address = searchParams.get('referral');
  const selleg = searchParams.get('leg');

  const [sleg, setsleg] = useState("left");
  const [referral, setreferral] = useState("");
  const [isreg, setisreg] = useState(null);
  const [total_tokens, settotal_tokens] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const onDeposit = async (amount, index) => {
    console.log("amount, index", amount, index);

    settrdone(true);

    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }
    if (!amount || amount < 0) {
      toastError("Enter amount");
      settrdone(false);
      return;
    }
    if (Number(amount) > balance) {
      toastError("Insufficient Balance");
      settrdone(false);
      // return;
    }
    if (!referral) {
      toastError("Enter Referral Address");
      settrdone(false);
      return;
    }

    var data = await getudata(referral);
    if (data.data.error) {
      toastError("Invalid Referral Address");
      settrdone(false);
      return "";
    }
    let token_contract = new ethers.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    const allowance = await token_contract.allowance(
      account,
      settings.contract
    );
    async function main() {
      // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
      //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
      //   console.log("gasghd ",gsl,await ethers.eth.getGasPrice()
      //   );

      try {
        var stk = await stakcontract.stake(
          referral,
          `${index}`
        );
        const receipt = await stk.wait();
        // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');
        sethash(receipt.transactionHash);
        // sethash(randomHash);
        getsmcs();
        settrdone(false);
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "transaction",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            address: account,
            ref_address: referral,
            hash: receipt.transactionHash,
            // hash: randomHash,
            amount: amount,
            token: total_tokens,
            rate: tokenrate,
            leg: sleg === "left" ? 0 : 1,
          })
          .then((res) => {
            // var dedata = decryptData(res.data);
            if (res.data.error) {
              toastError(res.data.error);
              return "";
            }
            getuserDB();
            toastSuccess("Transaction done");
          });
      } catch (error) {
        console.log(error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }
    }

    if (Number(ethers.utils.formatEther(allowance)) < amount) {
      // if (false) {
      try {
        var approv = await token_contract.approve(
          settings.contract,
          ethers.utils.parseEther("100000000")
        );
        const receipt = await approv.wait();
        console.log(receipt);
        toastSuccess("Token Approved");
        main();
      } catch (error) {
        console.log("error", error);
        toastError("Token not approved");
        settrdone(false);
        return;
      }
    } else {
      main();
    }
  };
  useEffect(() => {
    if (dbuser !== null) {
      if (dbuser.ref_address !== null) {
        setreferral(dbuser.ref_address);
        setisreg(true);
      }
    } else {
      setreferral(link_ref_address);
      setsleg(selleg);
    }
  }, [link_ref_address, selleg, dbuser, account]);
  // useEffect(() => {
  //   settotal_tokens(amount / tokenrate);
  // }, [amount]);

  return (
    <div>
      <div className="about-area">
        <div className="container-fluid">
          <h5 className=" text-start">Subscriptions </h5>

          <div className="row g-5 justify-content-center">
            <div className="col-12 col-lg-8">
              {/* Create New Form */}
              <div className="create-new-form border shadow-sm p-4 p-sm-5 card about-card shadow-sm ">
                <form action="#">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <div className="form-group mb-4 text-start">
                        <label className="mb-2 fz-16" htmlFor="title">
                          Referral Address
                        </label>
                        {dbuser !== null ? (
                          dbuser.referral !== null ? (
                            <input
                              className="form-control"
                              type="text"
                              value={referral}
                            />
                          ) : (
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter Referral Address"
                              value={referral}
                              onChange={(e) =>
                                setreferral(e.target.value)
                              }
                            />
                          )
                        ) : (
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Referral Address"
                            value={referral}
                            onChange={(e) =>
                              setreferral(e.target.value)
                            }
                          />
                        )}

                      </div>
                    </div>
                    <div className="col-12">
                      {!isreg ? (
                        <>
                          <div className="form-group mb-4">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="inlineRadio2"
                                type="radio"
                                name="leg"
                                value="left"
                                checked={sleg === 'left'}
                                defaultChecked={true}
                                onChange={(e) =>
                                  setsleg(
                                    e.target.value
                                  )
                                }

                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio2"
                              >
                                Left
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                id="inlineRadio3"
                                type="radio"
                                name="leg"
                                value="right"
                                checked={sleg === 'right'}
                                onChange={(e) =>
                                  setsleg(
                                    e.target.value
                                  )
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio3"
                              >
                                Right
                              </label>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {hash ? (
                        <div className="p-3  text-center">
                          Hash :{" "}
                          <a
                            href={`${process.env.REACT_APP_EXPLORER}tx/${hash}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            #: {formatAddress(hash)}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row g-4 justify-content-center pt-5 ">
            {trdone ?
              <img src="assets/img/loader.gif" alt="" srcset="" style={{ "height": "100px", width: "120px" }} /> :
              <>
                {bots.map((e, i) => {
                  return (
                    <div className="col-12 col-sm-6 col-xl-3 ">
                      <div className="card about-card shadow-sm ">
                        <div className="card-body py-4 text-start">
                          <div className="text-start">
                            <img
                              src="assets/img/new-img/bronze.png"
                              style={{ height: "80px" }}
                              alt=""
                            />
                          </div>
                          <h5 className="mb-3">{e.name}</h5>

                          <h3 className="mb-3 mt-3">$ {e.amount}</h3>
                          <button
                            className="btn new-button rounded-pill btn-sm mt-3 w-100"
                            onClick={() => onDeposit(e.amount, e.si)}
                          >
                            Pay Now
                          </button>
                        </div>
                      </div>
                    </div>

                  )
                })}
              </>
            }
          </div>
        </div>
        {/* CTA Wrapper */}
      </div>
    </div>
  );
}
