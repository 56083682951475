import React from "react";

export default function MarketMain() {
  return (
    <div>
      <div className="container-fluid">
      <div className="row g-4  mt-3">
      <h5 className=" text-start">Market Opportunities </h5>
  
          <div className="col-12 col-md-6 col-xl-4 ">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Panding Withdrawal <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ 00</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 ">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Panding Withdrawal <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ 00</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 ">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Panding Withdrawal <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ 00</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 ">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Panding Withdrawal <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ 00</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 ">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Panding Withdrawal <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ 00</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 ">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Panding Withdrawal <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ 00</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
