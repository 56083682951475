import React from 'react'
import Header from '../../Coman/Header'
import Sidebar from '../../Coman/Sidebar'

import PerformanceTable from './PerformanceTable'

export default function Performanceincome() {
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="admin-wrapper">
        <PerformanceTable />
      </div>
    </div>
  )
}
