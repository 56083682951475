import React from "react";
import Header from "../../Coman/Header";
import Sidebar from "../../Coman/Sidebar";
import PurchaseTable from "./PurchaseTable";

export default function Purchase() {
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="admin-wrapper">
        <PurchaseTable />
      </div>
    </div>
  );
}
