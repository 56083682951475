import React from 'react'
import Header from '../../Coman/Header'
import Sidebar from '../../Coman/Sidebar'
import MatchingIncomHero from './MatchingIncomHero'

export default function MatchingIncom() {
  return (
    <div>
        <Header/>
        <Sidebar/>
        <div className='admin-wrapper'>
        <MatchingIncomHero/>
        </div>
    </div>
  )
}
