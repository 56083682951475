import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { ProjectContext } from "../../../ProjectContext";
import Pagination from "../../Coman/Pagination";

export default function SponsorTable() {
  const { account, copyaddress, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "direct",
        submethod: "getbyid",
        // address: "default",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);

  return (
    <div>
      <div className="container-fluid">
        <div className="row g-4 text-start">
          <div className="col-12">
            <h5 class=" text-start mb-4 dart-text">Sponsor History </h5>
            <div className="card border-0 shadow-sm">
              <div className="card-body p-4">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="tab1"
                    role="tabpanel"
                    aria-labelledby="tab--1"
                  >
                    <div className="table-responsive border shadow-sm dashboard-table activity-table">
                      <table className="table mb-0">
                        <tbody>
                          <tr className="bg-tble">
                            <th scope="row">
                              <a
                                className="btn btn-minimal text-black hover-primary"
                                href="#"
                              >
                                #
                              </a>
                            </th>
                            <td className="text-black">From</td>
                            <td className="text-black">Staking</td>
                            <td className="text-black">Income(%)</td>
                            <td className="text-black">Datetime</td>
                          </tr>
                          {isLoading ?
                            <tr className="text-center">
                              <td className="text-center" colSpan={10}>
                                "Data is loading"
                              </td>
                            </tr> : ""}
                          {!isLoading ? (
                            !data.length ? (
                              <tr className="text-center">
                                <td className="text-center" colSpan={10}>
                                  <span className="w-100">No data found</span>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {data.map((ele, i) => {
                            return (
                              <tr>
                                <th scope="row">
                                  {ele.id}
                                </th>
                                <td>
                                  <span className="d-inline-block fw-bold fz-14">
                                    <p
                                      onClick={() => copyaddress(ele.from)}
                                    >
                                      # {formatAddress(ele.from)}{" "}
                                      <i className="bi bi-clipboard"></i>
                                    </p>
                                  </span>
                                </td>
                                <td>$ {ele.staking}</td>
                                <td>$ {ele.income} ({ele.income_per}%)</td>
                                <td>{ele.createdAt}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
