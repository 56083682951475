import React, { useContext, useState } from "react";
import axios from "axios";
import { ethers } from "ethers";
import { ProjectContext } from "../../ProjectContext";
import CountdownTimer from "../Coman/CountdownTimer";

export default function Maind() {
  const { account, settings, esigner, stats, dbuser, getsmcs, user_stats, toastError, toastSuccess } = useContext(ProjectContext);
  const [trdone, settrdone] = useState(false);


  const getData = async () => {
    if (!account) {
      toastError("Connect first")
      return
    }
    settrdone(true);

    // var data = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
    //   method: "trade",
    //   submethod: "get",
    //   key: process.env.REACT_APP_KEY,
    // });
    // var resdata = data.data
    // console.log("ew", resdata);
    console.log("in");

    if (!settings.trade_contract || esigner === null) return;
    console.log("out");
    let trade_contract = new ethers.Contract(
      settings.trade_contract,
      JSON.parse(settings.trade_contract_abi),
      esigner
    );
    let arbt_token = new ethers.Contract(
      settings.arbt_token,
      JSON.parse(settings.arbt_token_abi),
      esigner
    );
    if (user_stats.isPurchased === false) {
      // if (Number(library.utils.fromWei(allowance, "ether")) < total_tokens) {
      // if (false) {
      try {
        var approv = await arbt_token.approve(
          settings.trade_contract,
          ethers.utils.parseEther("100000000")
        );
        const receipt = await approv.wait();
        console.log(receipt);
        toastSuccess("Token Approved");
        // main();
      } catch (error) {
        console.log("error", error);
        toastError("Token not approved");
        settrdone(false);
        return;
      }
    }


    try {
      var resdata = {
        "trade": {
          "trade": [
            [
              "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
              "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
              "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506"
            ],
            [
              "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
              "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
              "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506"
            ]
          ],
          "amountIn": 0.0038,
          "amountOut": "0.003750377580240235"
        }
      }
      var stk = null
      function getRandomNumber() {
        return Math.floor(Math.random() * 3) + 10;
      }

      stk = await trade_contract.initateArbitrage(
        "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
        `${getRandomNumber()}000000`,
        "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
        resdata.trade.trade
      );
      // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

      const receipt = await stk.wait();
      // sethash(receipt.transactionHash);
      // sethash(randomHash);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "trade",
          submethod: "insert",
          key: process.env.REACT_APP_KEY,
          address: account,
          trade: resdata,
          hash: receipt.transactionHash
        })
        .then((res) => {
          console.log("res", res.data);
          // var dedata = decryptData(res.data);
          if (res.data.error) {
            return "";
          }
        });
      settrdone(false);
      toastSuccess("Transaction done");

      getsmcs()
    } catch (error) {
      console.log("Error", error);
      settrdone(false);
      toastError("Transaction declined");

      return;
    }
  };
  
  return (
    <div>
      <div className="">
        <div className="">
          <div className="container-fluid">
            <div className="row g-4 text-start">
              <div className="col-12 col-md-6 col-xl-4">
                <div className="card border-0 shadow-sm ">
                  <div className="card-body p-0 pb-3">
                    <h6 className="pt-2 pb-0 text-center">
                      TVL <span className="text-success"></span>
                    </h6>
                    <hr className="mt-0" />
                    <div className="px-5 d-flex align-items-center justify-content-center">
                      <h5>$ {stats ? stats.totalTradeAmt : 0}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div className="card border-0 shadow-sm ">
                  <div className="card-body p-0 pb-3">
                    <h6 className="pt-2 pb-0 text-center">
                      Total Trade <span className="text-success"></span>
                    </h6>
                    <hr className="mt-0" />
                    <div className="px-5 d-flex align-items-center justify-content-center">
                      <h5>{stats ? stats.totalTrades : 0}</h5>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div className="card border-0 shadow-sm ">
                  <div className="card-body p-0 pb-3">
                    <h6 className="pt-2 pb-0 text-center">
                      Total Profit <span className="text-success"></span>
                    </h6>
                    <hr className="mt-0" />
                    <div className="px-5 d-flex align-items-center justify-content-center">

                      <h5>$ {stats ? stats.totalProfit : 0}</h5>

                      <div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="card border-0 shadow-sm ">
                  <div className="card-body pt-1 pb-4">
                    {new Date("2024-11-12 19:31:00") < new Date()?
                    
                    <div className="d-flex gap-4  justify-content-center">
                      <button class="btn btn-primary py-2 mt-3 " onClick={() => window.location.reload()}>
                        Refresh
                      </button>
                      {trdone ?
                        <img src="assets/img/loader.gif" alt="" srcset="" style={{ width: "70px" }} /> :

                        <>
                          {user_stats ?
                            new Date(user_stats.nextcall) > new Date() ?
                              <CountdownTimer targetDate={user_stats ? new Date(user_stats.nextcall) : 0} /> :
                              <button class="btn btn-primary py-2 mt-3" onClick={() => getData()}>
                                Trade
                              </button>
                            :
                            ''}
                        </>
                      }
                    </div>:
                    <div className="d-flex  justify-content-center">
                      <CountdownTimer targetDate={"2024-11-12 19:31:00"} />
                    </div>}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div className="card border-0 shadow-sm ">
                  <div className="card-body p-0 pb-3">
                    <h6 className="pt-2 pb-0 text-center">
                      Current Bot <span className="text-success"></span>
                    </h6>
                    <hr className="mt-0" />
                    <div className="px-5 d-flex align-items-center justify-content-center">
                      <h5>${dbuser ? dbuser.c_staking : 0}</h5>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div className="card border-0 shadow-sm ">
                  <div className="card-body p-0 pb-3">
                    <h6 className="pt-2 pb-0 text-center">
                      Profit <span className="text-success"></span>
                    </h6>
                    <hr className="mt-0" />
                    <div className="px-5 d-flex align-items-center justify-content-center">
                      <h5>$ {user_stats ? user_stats.totalProfit : 0}</h5>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div className="card border-0 shadow-sm ">
                  <div className="card-body p-0 pb-3">
                    <h6 className="pt-2 pb-0 text-center">
                      Today Pending Trades <span className="text-success"></span>
                    </h6>
                    <hr className="mt-0" />
                    <div className="px-5 d-flex align-items-center justify-content-center">
                      <h5>{user_stats ? user_stats.totalTrades - user_stats.claimedTradesPerDay : 0}</h5>

                      <div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
}