import React, { useState } from "react";

export default function Header() {

  const [button, setButton] = useState(false);

  const handleChange = () => {
      setButton(!button);
      handleToggle(!button); // Call handleToggle with the new state
  };
  
  const handleToggle = (isActive) => {
      const body = document.body;
      if (isActive) {
          body.classList.add('dashboard-header-active');
          localStorage.setItem('darkMode', 'true');
      } else {
          body.classList.remove('dashboard-header-active'); // Correct class name
          localStorage.setItem('darkMode', 'false');
      }
  };
  return (
    <div>
     
    </div>
  );
}
