import React, { useContext } from "react";
import { ProjectContext } from "../../../ProjectContext";
import { Line } from "rc-progress";
export default function ProfileMain() {
  const { dbuser, account, copyaddress, formatAddress } = useContext(ProjectContext);
  return (
    <div>
      <div className="container-fluid text-start">
        <div className="row pt-4 justify-content-center">
          <h4 className=" text-start">Profile </h4>
          <div className="col-12 col-lg-8">
            {/* Create New Form */}
            <div className="create-new-form border shadow-sm p-4 p-sm-5 card about-card shadow-sm ">
              <form action="#">
                <div className="row align-items-center justify-content-center">
                  <div className="col-xl-6 text-start d-flex justify-content-center">
                    <div>
                      <h6>My Adress : <span onClick={() => copyaddress(account)}>{formatAddress(account)}<i class="bi bi-clipboard ms-1 cursor-pointer mt-5"></i></span></h6>
                      <h6>My Referral Address : <span onClick={() => copyaddress(dbuser.ref_address)}>{dbuser !== null
                        ? formatAddress(dbuser.ref_address)
                        : "-"}{" "} <i class="bi bi-clipboard ms-1 cursor-pointer"></i></span></h6>
                    </div>
                  </div>
                  <div className="col-xl-6 text-start d-flex justify-content-center">
                    <div>
                      <h5>Sponsor Links</h5>
                      <h6 className="mt-3">Right : <span onClick={() => copyaddress(`${process.env.REACT_APP_LINK}subscriptions?leg=right&referral=${account}`)}>Right Leg <i class="bi bi-clipboard ms-1 cursor-pointer"></i></span></h6>
                      <h6>Left : <span onClick={() => copyaddress(`${process.env.REACT_APP_LINK}subscriptions?leg=left&referral=${account}`)}>Left Leg <i class="bi bi-clipboard ms-1 cursor-pointer"></i></span></h6>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row pt-3">
          <h5 className="m-0">Reward Cappability</h5>
          <div className="p-5 pt-2">
            <div className="col-12">
              <div className="create-new-form border shadow-sm p-4  card about-card shadow-sm " title={dbuser ? `${Number(dbuser.x_per).toFixed(4)}%` : 0}>
                <Line percent={dbuser ? dbuser.x_per : 0} strokeWidth={1} strokeColor="#00eeff"  />
                <div className="d-flex justify-content-between">
                  <h6>$0</h6>
                  <h6>${dbuser ? dbuser.x_income : 0}</h6>
                  <h6>${dbuser ? dbuser.x_t_income : 0}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-4">

          <h5 className="m-0">Statistics</h5>
          <div className="col-12 col-md-6 col-xl-4">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Matching Bonus <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>{dbuser ? dbuser.matching_bonus ? "Eligible" : "Not Eligible" : '-'}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Balance <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ {dbuser ? dbuser.balance : 0}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Current Bot <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ {dbuser ? dbuser.c_staking : 0}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Total Bot <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ {dbuser ? dbuser.t_staking : 0}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Capability <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>{dbuser ? dbuser.c_capp : 0}x</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Sponsor Reward <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ {dbuser ? dbuser.direct_rew : 0}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 ">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Matching Reward<span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ {dbuser ? dbuser.binary_rew : 0}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 ">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Performance Reward<span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ {dbuser ? dbuser.performance_rew : 0}</h5>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-4  mt-3">
          <h5 className="mb-1">Profile</h5>
          <div className="col-12 col-md-6 col-xl-4 ">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Withdrawal <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-around">
                  <h5>$ {dbuser ? dbuser.t_withdrawal : 0}</h5>
                </div>
                <div className="d-flex justify-content-center">

                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 ">
            <div className="card border-0 shadow-sm ">
              <div className="card-body p-0 pb-3">
                <h6 className="pt-2 pb-0 text-center">
                  Pending Withdrawal <span className="text-success"></span>
                </h6>
                <hr className="mt-0" />
                <div className="px-5 d-flex align-items-center justify-content-center">
                  <h5>$ {dbuser ? dbuser.pen_withdrawal : 0}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
