import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../ProjectContext";
import axios from "axios";

export default function DashboardTable() {
  const { account, copyaddress, getSymbolByAddress,formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "trade",
        submethod: "getbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);
  return (
    <div className="container-fluid">
      <div className="row g-4 text-start mt-3">
        <div className="col-12">

          <div className="col-12 mt-2">
            <div className="card border-0 shadow-sm">
              <div className="card-body p-4">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                  <h5>Last Trade</h5>
                </div>

                <div>
                  <div className="table-responsive border shadow-sm activity-table">
                    <table className="table mb-0">
                      <tbody>
                        <tr className="bg-tble">
                          <td>
                            <span className="d-inline-block fw-bold fz-14 text-black">
                              Borrow Amount
                            </span>
                          </td>
                          <td className="text-black">Pairs</td>
                          <td className="text-black">Profit Amount (USD)</td>
                        </tr>
                        {data.map((ele, i) => {

                          return (
                            <tr>
                              <td>
                                {ele.trade[0].trade.amountIn}
                              </td>
                              <td>{getSymbolByAddress(ele.trade[0].trade.trade[0][0])} - {getSymbolByAddress(ele.trade[0].trade.trade[0][1])} </td>
                              <td>{ele.profit} {getSymbolByAddress(ele.profit_token ? ele.profit_token : "address")}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
